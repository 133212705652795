import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
  Container,
  Section,
  Subtitle as DefaultSubtitle,
} from '@components/styledComponents/index';
import ServicesSlider from '@components/services-slider/index';
import {
  FeatureImg,
  FeatureSection,
  FeatureText,
  Subtitle,
  Title,
  Text,
  MessageBox,
  MessageText,
  MessageOwner,
  OwnerMsgName,
  OwnerMsgPostion,
  FounderTitle,
  MemberBox,
  MemberBoxImage,
  MemberBoxTitle,
  MemberBoxText,
  MemberBoxSubtitle,
} from './styles';

const AboutUs = () => {
  const theme = useContext(ThemeContext);
  return (
    <>
      <FeatureSection>
        <FeatureImg
          alt="About us"
          src={require('@images/about-featured.jpg')}
          srcSet={`${require('@images/about-featured.jpg')}, ${require('@images/about-featured@2x.jpg')} 2x`}
        />
        <FeatureText>
          <div>
            <Subtitle>Our mission</Subtitle>
            <Title>
              Helping the GCC’s leading organisations thrive in the new
              experience-driven digital landscape
            </Title>
            <Text>
              Headquartered in Riyadh, Saudi Arabia, Wareef is an IT solution
              provider that has offered customized, business-led solutions to
              the GCC market since 1998. We specialize in end-to-end solutions
              with a modular approach, including expertise in vendor management,
              quality assurance, project management, logistics and local, Arabic
              content.
            </Text>
          </div>
        </FeatureText>
      </FeatureSection>
      <Container bgColor={theme.secondaryBgColor} deskMargin={'0 0 14rem'}>
        <Section>
          <DefaultSubtitle
            fontSize={'1.2rem'}
            align={'center'}
            deskAlign={'center'}
          >
            Message from Wareef’s founder
          </DefaultSubtitle>
          <MessageBox>
            <MessageText>
              “I’ve always been interested in the study of humanity; its past,
              present, and future. It’s the lens my background as an
              anthropologist has imbued me with and coloured my view of the
              world. One of the dimensions of our species’ evolution that has
              always caught my eye since its early stages of development has
              been the Informational Technology sector. After all, building the
              information highway and the global communication infrastructure is
              like building windows and doorways for humanity to see, listen,
              and touch more of each other. In short, changing the
              infrastructure also changes the content of the superstructure.
              This is where the idea of Wareef was conceived, an extension of my
              curiosity in our humanity and a participation point in that
              dialogue and landscape. May the future superstructure of the
              various cultures of humanity, be increasingly species conscious.”
            </MessageText>
          </MessageBox>

          <MessageOwner>
            <OwnerMsgName>Dr. Abdulaziz Aloshban</OwnerMsgName>
            <OwnerMsgPostion>Chairman and CEO</OwnerMsgPostion>
          </MessageOwner>
        </Section>
      </Container>
      <Container>
        <Section>
          <FounderTitle>Wareef’s Management Team</FounderTitle>

          <MemberBox>
            <MemberBoxImage
              alt="Deputy CEO image"
              src={require('@images/dr-Abdul-Aziz.jpg')}
              srcSet={`${require('@images/dr-Abdul-Aziz.jpg')}, ${require('@images/dr-Abdul-Aziz@2x.jpg')} 2x`}
            />
            <div>
              <MemberBoxTitle>Dr. Abdulaziz Aloshban</MemberBoxTitle>
              <MemberBoxSubtitle>Chairman and CEO</MemberBoxSubtitle>
              <MemberBoxText>
                Dr. Abdulaziz founded Wareef in 1998 as one of the first
                companies to offer the IT outsourcing model in the Kingdom.
                Previously, he was general manager of Ace Travel and AlQafila,
                as well as an associate professor of anthropology at King Saud
                University. He earned a Bachelors degree in Anthropology, with a
                minor in Mechanical Engineering, in addition to a Masters in
                Interdisciplinary Studies from Oregon State University and a
                Masters and PhD in Anthropology from Northwestern University.
              </MemberBoxText>
            </div>
          </MemberBox>

          <MemberBox>
            <MemberBoxImage
              alt="Deputy CEO image"
              src={require('@images/ghassan.jpg')}
              srcSet={`${require('@images/ghassan.jpg')}, ${require('@images/ghassan@2x.jpg')} 2x`}
            />
            <div>
              <MemberBoxTitle>Ghassan Aloshban</MemberBoxTitle>
              <MemberBoxSubtitle>
                Deputy CEO and Head of Finance
              </MemberBoxSubtitle>
              <MemberBoxText>
                Ghassan joined Wareef in 2011 to oversee business planning and
                strategy development, as well as corporate structure and
                governance. Prior to joining Wareef, he worked as a senior
                analyst at Saudi Aramco, where he offered consultations on
                strategic planning to executive management and Saudi Arabia’s
                Ministry of Petroleum. He holds a Bachelors degree in Computer
                Science and a Bachelors degree in Economics from Amherst College
                as well as an MBA from Harvard Business School.
              </MemberBoxText>
            </div>
          </MemberBox>
        </Section>
      </Container>
      <Container bgColor={theme.secondaryBgColor} deskDir={'column'}>
        <Section padding={'3.6rem 0 2.8rem'} flexAlign="center">
          <DefaultSubtitle align="center">Our services</DefaultSubtitle>
        </Section>
        <Section padding={'1.7rem 1.4rem 3.5rem'}>
          <ServicesSlider tabs={true} />
        </Section>
      </Container>
    </>
  );
};

export default AboutUs;
