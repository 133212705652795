import styled from 'styled-components';

export const FeatureSection = styled.div`
  position: relative;
`;

export const FeatureImg = styled.img`
  width: 100%;
  height: 21rem;
  object-fit: cover;
  @media (min-width: 1200px) {
    height: 43.7rem;
  }
`;

export const FeatureText = styled.div`
  width: auto;
  background: ${props => props.theme.quoteTxtColor}
    url(${require('@images/logo_inbg.svg')}) no-repeat -11rem 0rem;
  background-size: 46.2rem 37.2rem;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  margin: -8.2rem 1.4rem 0;
  > div {
    padding: 1.8rem 1.4rem 2.4rem;
  }
  @media (min-width: 1200px) {
    width: 107.5rem;
    margin: -8.2rem auto 0;
    background-size: 53.7rem 43.2rem;
    > div {
      padding: 3.8rem 1.4rem 2.4rem;
      margin-bottom: 3.9rem;
    }
  }
`;

export const Subtitle = styled.h3`
  font-family: 'Avenir';
  font-weight: 600;
  font-size: 1.2rem;
  color: ${props => props.theme.sliderTitle};
  letter-spacing: 0.185rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  margin: 0;
  @media (min-width: 1200px) {
    color: ${props => props.theme.white};
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
  }
`;

export const Title = styled.h2`
  font-family: 'Avenir';
  font-weight: 600;
  font-size: 2.2rem;
  color: ${props => props.theme.white};
  letter-spacing: 0;
  line-height: 4.2rem;
  margin: 0;
  @media (min-width: 1200px) {
    margin: 0 16rem 2rem;
    font-family: 'Heuristica';
    font-weight: 700;
    font-size: 3.2rem;
    text-align: center;
    line-height: 4.6rem;
  }
`;

export const Text = styled.p`
  font-family: 'Avenir Book';
  font-size: 1.4rem;
  color: ${props => props.theme.white};
  letter-spacing: 0;
  line-height: 2.1rem;
  margin: 0 0 1rem 0;

  @media (min-width: 1200px) {
    font-family: 'Avenir';
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.6rem;
    margin: 0 10rem 1rem;
  }
`;

export const MessageBox = styled.div`
  background: url(${require('@images/quote.svg')}) no-repeat center top;
  background-size: 8rem 6.1rem;
  padding: 3.9rem 2rem 2.4rem;
  @media (min-width: 1200px) {
    padding: 3rem 2rem 2.4rem;
  }
`;

export const MessageText = styled.div`
  font-family: 'Avenir Book Oblique';
  font-size: 1.4rem;
  color: ${props => props.theme.quoteTxtColor};
  letter-spacing: 0;
  line-height: 2.2rem;

  @media (min-width: 1200px) {
    font-size: 1.8rem;
    line-height: 3rem;
    text-align: center;
    padding: 0 16rem;
  }
`;

export const MessageOwner = styled.div`
  width: auto;
  background: ${props => props.theme.quoteTxtColor}
    url(${require('@images/logo_inbg2.svg')}) no-repeat 6.5rem -12.2rem;
  background-size: 32.3rem 26rem;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  margin: 0 2.6rem 0;
  padding: 1.7rem 0 2rem;
  @media (min-width: 1200px) {
    background-size: 32.3rem 26rem;
    background-position: 26rem -6rem;
    bottom: -5.8rem;
    position: absolute;
    right: 0;
    padding: 3.2rem 15.3rem 3.2rem 3.4rem;
    margin-right: 0;
    z-index: 2;
  }
`;

export const OwnerMsgName = styled.h3`
  margin: 0;
  padding: 0;
  font-family: 'Heuristica';
  font-weight: 400;
  font-size: 1.8rem;
  color: ${props => props.theme.white};
  letter-spacing: 0;
  line-height: 2.52rem;
`;

export const OwnerMsgPostion = styled.h4`
  margin: 0;
  padding: 0;
  font-family: 'Avenir Book';
  font-size: 1.4rem;
  color: ${props => props.theme.white};
  letter-spacing: 0;
  line-height: 1.56rem;
  @media (min-width: 1200px) {
    font-size: 1.6rem;
    font-weight: 400;
    text-align: left;
  }
`;

export const FounderTitle = styled.h3`
  margin: 0 0 2.6rem;
  padding: 0;
  font-family: 'Avenir Book Oblique';
  font-size: 1.5rem;
  color: ${props => props.theme.subtitleColor};
  letter-spacing: 0.2rem;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: 1200px) {
    font-family: 'Avenir';
  }
`;

export const MemberBox = styled.div`
  width: 100%;
  background: ${props => props.theme.quoteTxtColor}
    url(${require('@images/logo_inbg.svg')}) no-repeat -6.6rem 18rem;
  background-size: 27rem 21.8rem;
  box-sizing: border-box;
  text-align: left;
  margin-bottom: 2.5rem;
  > div {
    padding: 1.8rem 1.4rem 2.2rem;
  }
  @media (min-width: 500px) {
    display: grid;
    grid-template-columns: 19.5rem auto;
    min-height: 30rem;
    width: auto;
  }

  @media (min-width: 1200px) {
    background-size: 53.7rem 43.2rem;
    background-position: 12rem 0;
    margin: 0 15rem 3.6rem;
    > div {
      padding: 1.8rem 2.8rem 2.2rem 3.8rem;
    }
  }
`;

export const MemberBoxImage = styled.img`
  height: 22.5rem;
  width: 100%;
  object-fit: cover;
  object-position: center -2.5rem;
  @media (min-width: 500px) {
    height: auto;
    align-self: stretch;
    object-position: center center;
  }
  @media(min-width: 1200px) {
    object-position: unset;
  }
`;

export const MemberBoxTitle = styled.h2`
  font-family: 'Avenir';
  font-weight: 600;
  font-size: 2.2rem;
  color: ${props => props.theme.white};
  letter-spacing: 0;
  line-height: 4.2rem;
  margin: 1.6rem 0 0;
  @media(min-width: 1200px) {
    font-family: 'Heuristica';
    font-weight: 400;
    font-size: 2.8rem;
    margin: 0.4rem 0 0;
  }
`;

export const MemberBoxSubtitle = styled.h3`
  font-family: 'Avenir Book';
  font-size: 1.6rem;
  color: ${props => props.theme.white};
  letter-spacing: 0;
  line-height: 1.56rem;
  margin: 0 0 2.1rem;
  @media(min-width: 1200px) {
    font-weight: 400;
  }
`;

export const MemberBoxText = styled.p`
  font-family: 'Avenir Book';
  font-size: 1.4rem;
  color: ${props => props.theme.white};
  letter-spacing: 0;
  line-height: 2.1rem;
  margin: 0;

  @media(min-width: 1200px) {
    font-family: 'Avenir Roman';
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
`;
