import React from 'react';
import Layout from '@layouts/default';
import SEO from '@layouts/seo';
import AboutUs from '@components/pages/aboutus/index';

const AboutUsPage = props => {
  return (
    <Layout {...props}>
      <SEO title="About Us" />
      <AboutUs />
    </Layout>
  );
};

export default AboutUsPage;
